import {
  IClipControlProps,
  RegionData,
} from '@/pages/editor/component/LiveWindow/component/ClipControl/ClipControl'
import { deepCopy } from '@/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface IPrepareTransition {
  clipIndex: number
  leftPostion: number
  trackIndex: number
}
const initialState: {
  clipIndex: number
  trackIndex: number
  sliderMenuKey: string
  editPanel: string
  prepareTransition: IPrepareTransition | null
  showReplaceClipDialog: boolean
  showReplacClipModal: boolean
  pieceSelectedResource: null | IFileResource
  currentMenuItem: string
  currentSubMenuItem: string
  currentCropRegion: null | RegionData
  clipControlProps: null | IClipControlProps
  subtitlesPageStatus: string
} = {
  clipIndex: -1,
  trackIndex: -1,
  editPanel: '',
  sliderMenuKey: 'resource',
  prepareTransition: null,
  showReplaceClipDialog: false,
  showReplacClipModal: false,
  pieceSelectedResource: null,
  currentMenuItem: '',
  currentSubMenuItem: '',
  currentCropRegion: null,
  clipControlProps: null,
  subtitlesPageStatus: 'preview',
}

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    updateEditClipInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        trackIndex: number
        clipIndex: number
      }>,
    ) {
      state.clipIndex = payload.clipIndex
      state.trackIndex = payload.trackIndex
    },
    updateSliderMenuKey(state, { payload }: PayloadAction<string>) {
      state.sliderMenuKey = payload
    },
    updateEditPanel(state, { payload }: PayloadAction<any>) {
      state.editPanel = payload
    },
    updatePrepareTransition(
      state,
      { payload }: PayloadAction<IPrepareTransition | null>,
    ) {
      state.prepareTransition = payload
    },
    isShowReplaceClipDialog(state, { payload }: PayloadAction<boolean>) {
      state.showReplaceClipDialog = payload
    },
    isShowReplaceClipModal(state, { payload }: PayloadAction<boolean>) {
      state.showReplacClipModal = payload
    },
    updatePieceSelectedResource(
      state,
      { payload }: PayloadAction<null | IFileResource>,
    ) {
      state.pieceSelectedResource = payload
    },
    updateCurrentMenuItem(state, { payload }: PayloadAction<string>) {
      state.currentMenuItem = payload
    },
    updateCurrentSubMenuItem(state, { payload }: PayloadAction<string>) {
      state.currentSubMenuItem = payload
    },
    updateCurrentCropRegion(
      state,
      { payload }: PayloadAction<null | RegionData>,
    ) {
      state.currentCropRegion = payload
    },
    updateClipControlProps(
      state,
      { payload }: PayloadAction<null | IClipControlProps>,
    ) {
      state.clipControlProps = deepCopy(payload)
    },
    updateSubtitlesPageStatus(state, { payload }: PayloadAction<string>) {
      state.subtitlesPageStatus = payload
    },
  },
})

export const editorActions = { ...editorSlice.actions }

export default editorSlice.reducer
