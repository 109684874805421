import Enum from '@/utils/enum'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ITask {
  progress: number
  jobId: number
  status: number
  currentSettingIndex: number
  advance: boolean
  advanceSizeLevel: number
  advanceBitrate: number | string
  advanceOutputType: string
  advanceFps: number
  projectSaveStatus: number
  showLatestExportVersion: boolean
  timelineUpdateTime: number
}

const initialState: ITask = {
  progress: 0,
  jobId: 0,
  status: 0,
  currentSettingIndex: 0,
  advance: false,
  advanceSizeLevel: 1080,
  advanceBitrate: 'low',
  advanceOutputType: 'mp4',
  advanceFps: 25,
  projectSaveStatus: Enum.projectSaveStatus.init,
  showLatestExportVersion: false,
  timelineUpdateTime: 0,
}

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    updateTimelineUpdateTime(state, { payload }: PayloadAction<number>) {
      state.timelineUpdateTime = payload
    },
    updateProgress(state, { payload }: PayloadAction<number>) {
      state.progress = payload
    },
    updateJobId(state, { payload }: PayloadAction<number>) {
      state.jobId = payload
    },
    updateStatus(state, { payload }: PayloadAction<number>) {
      state.status = payload
    },
    updateCurrentSettingIndex(state, { payload }: PayloadAction<number>) {
      state.currentSettingIndex = payload
    },
    updateAdvance(state, { payload }: PayloadAction<boolean>) {
      state.advance = payload
    },
    updateAdvanceSizeLevel(state, { payload }: PayloadAction<number>) {
      state.advanceSizeLevel = payload
    },
    updateAdvanceBitrate(state, { payload }: PayloadAction<number | string>) {
      state.advanceBitrate = payload
    },
    updateAdvanceOutputType(state, { payload }: PayloadAction<string>) {
      state.advanceOutputType = payload
    },
    updateAdvanceFps(state, { payload }: PayloadAction<number>) {
      state.advanceFps = payload
    },
    updateProjectSaveStatus(state, { payload }: PayloadAction<number>) {
      state.projectSaveStatus = payload
    },
    updateShowLatestExportVersion(state, { payload }: PayloadAction<boolean>) {
      state.showLatestExportVersion = payload
    },
  },
})

export const taskActions = { ...taskSlice.actions }

export default taskSlice.reducer
