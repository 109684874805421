import { MediaParams } from '@/pages/editor/utils/NvqParams'
import store from '@/store'
import { materialActions } from '@/store/material'
import { getUUIDFromStr, getVersionFromStr } from '@/utils'
import { TEMPLATE_PACKAGES } from '@/utils/constant'
import Enum from '@/utils/enum'
import EventBus, { onFinishAssetPackageInstallation } from '@/utils/EventBus'
import FSPath from '@/utils/FSPath'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { VideoClip } from '../ProjectData'

const name = 'nvBSEditorAssets'
const version = 13

let db: IDBDatabase
export function prepareAssetIndexDB() {
  let openRequest = window.indexedDB.open(name, version)
  let deleteRequest: IDBOpenDBRequest
  return new Promise((resolve, reject) => {
    openRequest.onerror = function (e) {
      console.error('open index db error, ', openRequest.error)
      deleteRequest = window.indexedDB.deleteDatabase(name)
      deleteRequest.onsuccess = function (e) {
        openRequest = window.indexedDB.open(name, version)
        openRequest.onsuccess = function (e) {
          const target = e.target as IDBOpenDBRequest | null
          if (target) {
            db = target.result
            console.warn('index db version:' + db.version)
            resolve(undefined)
          } else {
            reject(new Error('open index db error!'))
          }
        }
        openRequest.onupgradeneeded = function (e) {
          const target = e.target as IDBOpenDBRequest | null
          if (target) {
            console.warn('DB version upgrading...')
            const ret = target.result
            _buildAssetIndexedDB(ret)
          } else {
            reject(new Error('open index db error!'))
          }
        }
        openRequest.onerror = function (e) {
          console.error('open index db error, ', openRequest.error)
          reject(new Error('open index db error!'))
        }
      }
      deleteRequest.onerror = function (e) {
        console.error('delete index db error!', deleteRequest.error)
        reject(new Error('delete index db error!'))
      }
      reject(new Error('open index db error!'))
    }
    openRequest.onsuccess = function (e) {
      const target = e.target as IDBOpenDBRequest | null
      if (target) {
        db = target.result
        console.warn('index db version:' + db.version)
        resolve(undefined)
      } else {
        reject(new Error('open index db error!'))
      }
    }
    openRequest.onupgradeneeded = function (e) {
      const target = e.target as IDBOpenDBRequest | null
      if (target) {
        console.warn('DB version upgrading...')
        const ret = target.result
        _buildAssetIndexedDB(ret)
      } else {
        reject(new Error('open index db error!'))
      }
    }
  })
}

function _buildAssetIndexedDB(ret: IDBDatabase) {
  FSPath['editor'].forEach(ele => {
    ensureAssetIndexDBObject(ret, ele)
  })
}

function ensureAssetIndexDBObject(ret: IDBDatabase, name: string) {
  if (!ret.objectStoreNames.contains(name)) {
    ret.createObjectStore(name, { keyPath: 'id' })
  }
}

export function isMaterialExistInDB(key: any): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let objectStoreName: keyof typeof Enum.assetPackageType
    const suffix = key.split('.').pop()
    objectStoreName = suffix
    if (key.toLowerCase().search(/ttc|ttf|otf|woff$/) !== -1) {
      objectStoreName = 'font'
    } else if (key.includes('.arscene')) {
      objectStoreName = 'ARScene'
    } else if (key.includes('.videotransition')) {
      objectStoreName = 'transition'
    } else if (['.template', '.project'].includes(suffix)) {
      objectStoreName = 'template'
    }

    let keyUUID = key.split('.')[0]
    if (!keyUUID) {
      keyUUID = getUUIDFromStr(key)
    }

    let transaction = db.transaction(objectStoreName, 'readwrite')
    let dbStore = transaction.objectStore(objectStoreName)
    let request = dbStore.get(keyUUID)
    request.onsuccess = function (e) {
      if (request.result) {
        resolve(true)
      } else {
        resolve(false)
      }
    }

    request.onerror = function (event) {
      reject(false)
    }
  })
}

export function isMaterialInstalled(key: any): boolean {
  let type: number | string
  let objectStoreName: keyof typeof Enum.assetPackageType
  const suffix = key.split('.').pop()
  objectStoreName = suffix
  if (key.toLowerCase().search(/ttc|ttf|otf|woff$/) !== -1) {
    objectStoreName = 'font'
  } else if (key.includes('.arscene')) {
    objectStoreName = 'ARScene'
  } else if (key.includes('.videotransition')) {
    objectStoreName = 'transition'
  } else if (['.template', '.project'].includes(suffix!)) {
    objectStoreName = 'template'
  }
  type = (Enum.assetPackageType as Partial<typeof Enum.assetPackageType>)[
    objectStoreName
  ] as string

  const contextInstance = nvsGetStreamingContextInstance()

  // For special effect materials, determine whether they have been installed before installation
  if ((type as unknown as number) >= 0) {
    let keyUUID = key.split('.')[0]
    const status = contextInstance
      .getAssetPackageManager()
      .getAssetPackageStatus(keyUUID, type as unknown as number)
    if (status !== NvsAssetPackageStatusEnum.NotInstalled) {
      return true
    }
  }
  return false
}

/**
 *
 * @param {*} key
 * @param {*} checkVersion
 * @param {*} assetType resources belong to other resources, default false, used when installing watermark
 * @param {*} checkLic
 * @returns
 */
export function checkAndInstallAssetFromIndexDB(
  options: any = {},
): Promise<string> {
  const {
    key,
    checkVersion = true,
    assetType,
    checkLic = true,
    licenseUrl,
  } = options
  const contextInstance = nvsGetStreamingContextInstance()
  return new Promise((resolve, reject) => {
    let path: string
    let type: number | string
    let objectStoreName: keyof typeof Enum.assetPackageType
    const suffix = key.split('.').pop()
    objectStoreName = suffix
    if (key.toLowerCase().search(/ttc|ttf|otf|woff$/) !== -1) {
      objectStoreName = 'font'
    } else if (key.includes('.arscene')) {
      objectStoreName = 'ARScene'
    } else if (key.includes('.videotransition')) {
      objectStoreName = 'transition'
    } else if (['.template', '.project'].includes(suffix)) {
      objectStoreName = 'template'
    }
    path = '/' + objectStoreName
    type = (Enum.assetPackageType as Partial<typeof Enum.assetPackageType>)[
      objectStoreName
    ] as string
    if (assetType) {
      if (assetType === 'projectresource') {
        path = '/projectresource'
        // path = contextInstance.getAssetPackageManager().getTemplatePackageDirPath(projectId)
        let subFolderList = key.split('/')
        if (subFolderList.length > 1) {
          let wholeFolderPath = path
          for (let i = 0; i < subFolderList.length - 1; i++) {
            wholeFolderPath += '/' + subFolderList[i]
            if (!FS.analyzePath(wholeFolderPath).exists) {
              FS.mkdir(wholeFolderPath)
            }
          }
        }
        objectStoreName = assetType
      } else {
        path = '/' + assetType
        type = assetType
        objectStoreName = assetType
      }
    }

    let filePath = path + '/' + key
    let keyUUID = key.split('.')[0]
    if (!keyUUID) {
      keyUUID = getUUIDFromStr(key)
    }
    // For special effect materials, determine whether they have been installed before installation
    if ((type as number) >= 0) {
      const status = contextInstance
        .getAssetPackageManager()
        .getAssetPackageStatus(keyUUID, type as number)
      if (status !== NvsAssetPackageStatusEnum.NotInstalled) {
        resolve(key)
        return
      }
    }
    const versionFromUrl = getVersionFromStr(key)
    let transaction = db.transaction(objectStoreName, 'readwrite')
    let dbStore = transaction.objectStore(objectStoreName)
    let request = dbStore.get(keyUUID)
    request.onsuccess = function (e) {
      // @ts-expect-error
      let ret = e.target.result
      if (typeof ret === 'undefined' || typeof ret.data === 'undefined') {
        // console.log(key, ' is not exist, need download');
        reject(key)
        return
      }
      if (!ret.name.includes(keyUUID)) {
        console.warn(key, ' is not exist, version is not right, need download')
        reject(key)
        return
      }
      const versionFromDB = getVersionFromStr(ret.name)
      if (checkVersion && versionFromDB < versionFromUrl) {
        console.warn(
          `${key} version has updated from ${versionFromDB} to ${versionFromUrl}, need download`,
        )
        reject(key)
      } else {
        FS.writeFile(filePath, new Uint8Array(ret.data))
        if (['model', 'dat'].includes(type as string)) {
          resolve('')
        } else if ((type as number) >= 0) {
          if (checkLic) {
            let requestLic = db
              .transaction('lic', 'readwrite')
              .objectStore('lic')
              .get(keyUUID)
            requestLic.onsuccess = function (e) {
              let licFilePath = ''
              // @ts-expect-error
              let requestLicResult = e.target.result
              if (
                typeof requestLicResult !== 'undefined' &&
                typeof requestLicResult.data !== 'undefined'
              ) {
                licFilePath = path + '/' + keyUUID + '.lic'
                FS.writeFile(licFilePath, new Uint8Array(requestLicResult.data))
              }
              EventBus.$on(
                ('onFinishAssetPackageInstallation' +
                  filePath) as onFinishAssetPackageInstallation,
                async function slot(options) {
                  const {
                    assetPackageId,
                    assetPackageFilePath,
                    assetPackageType,
                    error,
                  } = options
                  EventBus.$off(
                    ('onFinishAssetPackageInstallation' + filePath) as any,
                    slot,
                  )
                  resolve(key)
                  if (error !== 0) {
                    console.error(
                      `${key}: Resource installation failed, error code: ${error}`,
                    )
                    if (licenseUrl) {
                      console.info('try to download again...')
                      // Download lic file
                      const licData = await axios.get(licenseUrl, {
                        responseType: 'arraybuffer',
                      })
                      await saveAssetToIndexDB(
                        key.split('.')[0] + '.lic',
                        new Uint8Array(licData.data),
                      )
                    }
                  }
                },
              )
              // When loading an existing project, resources will be preloaded. At this time, when traversing the resource list, the return value is 2, which will not be received and cannot be resolved
              contextInstance
                .getAssetPackageManager()
                .installAssetPackage(filePath, licFilePath, type as number)
            }
            requestLic.onerror = function (e) {
              console.error(e)
              reject(key)
            }
          } else {
            EventBus.$on(
              ('onFinishAssetPackageInstallation' +
                filePath) as onFinishAssetPackageInstallation,
              function slot(options: any = {}) {
                const {
                  assetPackageId,
                  assetPackageFilePath,
                  assetPackageType,
                  error,
                } = options
                FS.unlink(assetPackageFilePath, 0)
                EventBus.$off(
                  ('onFinishAssetPackageInstallation' + filePath) as any,
                  slot,
                )
                if (error === 0) {
                  resolve(key)
                } else {
                  resolve('')
                  console.error(
                    `${key}: Resource installation failed, error code: ${error}`,
                  )
                }
              },
            )
            contextInstance
              .getAssetPackageManager()
              .installAssetPackage(filePath, '', type as number)
          }
        } else if (type === -2) {
          const fontFamily = contextInstance.registerFontByFilePath(filePath)
          resolve(fontFamily)
        } else {
          resolve(filePath)
        }
      }
    }
    request.onerror = function (event) {
      console.error(event)
      reject(key)
    }
  })
}

/**
 *
 * @param {*} key
 * @param {*} value
 * @param {*} assetType resources belong to other resources, default false, used when installing watermark
 * @returns
 */
export function saveAssetToIndexDB(
  key: string,
  value: any,
  assetType?: string,
) {
  return new Promise((resolve, reject) => {
    let objectStoreName: string
    const suffix = key.split('.').pop()
    objectStoreName = suffix as string
    if (key.toLowerCase().search(/ttc|ttf|otf|woff$/) !== -1) {
      objectStoreName = 'font'
    } else if (key.includes('.videotransition')) {
      objectStoreName = 'transition'
    } else if (['.template', '.project'].includes(suffix as string)) {
      objectStoreName = 'template'
    } else if (key.includes('.arscene')) {
      objectStoreName = 'ARScene'
    } else if (assetType) {
      objectStoreName = assetType
    }
    let keyUUID = key.split('.')[0]
    if (!keyUUID) {
      keyUUID = getUUIDFromStr(key)
    }
    if (db !== undefined && db.objectStoreNames.contains(objectStoreName)) {
      const transaction = db.transaction(objectStoreName, 'readwrite')
      const dbStore = transaction.objectStore(objectStoreName)
      dbStore.put({ id: keyUUID, name: key, data: value })
      resolve(undefined)
    } else {
      console.error(
        objectStoreName + ' is not prepared while adding data ----',
        key,
      )
      reject(
        new Error(
          objectStoreName + ' is not prepared while adding data ----' + key,
        ),
      )
    }
  })
}

export async function saveAndInstallM3u8(item: IFileResource | MediaParams) {
  if (!item.m3u8Url) {
    console.error('m3u8 url is null')
    return
  }
  const packageName = item.m3u8Url.substring(item.m3u8Url.lastIndexOf('/') + 1)
  try {
    const filePath = (await checkAndInstallAssetFromIndexDB({
      key: packageName,
    })) as string
    item.m3u8Path = filePath
    // save and install alpha m3u8 file
    await saveAndInstallAlpha(item)
  } catch (error) {
    if (isEmpty(item.m3u8Url)) {
      return
    }
    try {
      const response = await axios.get(item.m3u8Url, {
        responseType: 'arraybuffer',
      })
      await saveAssetToIndexDB(packageName, new Uint8Array(response.data))
      const filePath = (await checkAndInstallAssetFromIndexDB({
        key: packageName,
      })) as string
      item.m3u8Path = filePath
      // save and install alpha m3u8 file
      await saveAndInstallAlpha(item)
    } catch (error) {
      console.error(error)
    }
  }
}

export function saveAndInstallPackage(
  asset: IMaterialResource,
  assetType?: string,
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    let url = asset?.packageUrl || asset?.packageRelativePath
    if (!url) {
      reject(new Error('asset is invalid'))
      return
    }
    let licUrl = asset.licenseUrl
    let packageName = url.substring(url.lastIndexOf('/') + 1) as string
    try {
      const filePath = await checkAndInstallAssetFromIndexDB({
        key: packageName,
        assetType,
      })
      resolve(filePath)
    } catch (e) {
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' })
        await saveAssetToIndexDB(
          packageName,
          new Uint8Array(response.data),
          assetType,
        )
        if (licUrl) {
          try {
            let keyUUID = packageName.split('.')[0]
            if (!keyUUID) {
              keyUUID = getUUIDFromStr(packageName)
            }
            const response = await axios.get(licUrl, {
              responseType: 'arraybuffer',
            })
            await saveAssetToIndexDB(
              keyUUID + '.lic',
              new Uint8Array(response.data),
            )
            const filePath = await checkAndInstallAssetFromIndexDB({
              key: packageName,
            })
            resolve(filePath)
          } catch (e) {
            console.error(e)
            reject(
              new Error(
                'download and install with license failed, ' + packageName,
              ),
            )
          }
        } else {
          try {
            const filePath = await checkAndInstallAssetFromIndexDB({
              key: packageName,
              assetType,
            })
            resolve(filePath)
          } catch (e) {
            console.error(e)
            reject(new Error('download and install failed, ' + packageName))
          }
        }
      } catch (e) {
        console.error(e)
        reject(new Error('save asset failed, ' + packageName))
      }
    }
  })
}

export async function saveAndInstallAlpha(item: IFileResource | MediaParams) {
  if (item.alphaM3u8Url && item.alphaM3u8Url !== '') {
    let fileName = item.alphaM3u8Url.substring(
      item.alphaM3u8Url.lastIndexOf('/') + 1,
    )
    try {
      const filePath = (await checkAndInstallAssetFromIndexDB({
        key: fileName,
      })) as string
      item.alphaM3u8Path = filePath
    } catch (error) {
      if (isEmpty(item.alphaM3u8Url)) {
        return
      }
      try {
        const response = await axios.get(item.alphaM3u8Url, {
          responseType: 'arraybuffer',
        })
        await saveAssetToIndexDB(fileName, new Uint8Array(response.data))
        const filePath = (await checkAndInstallAssetFromIndexDB({
          key: fileName,
        })) as string
        item.alphaM3u8Path = filePath
      } catch (error) {
        console.error(error)
      }
    }
  }
}

/**
 * Audio, Video Download Resources
 * @param {*} key
 * @param {*} assetUrl
 * @param {*} videoClip Only required for video clips, not for audio
 * @returns
 */
export async function loadAndInstallM3u8(
  key: string,
  assetUrl: string,
  videoClip: VideoClip = {} as VideoClip,
) {
  try {
    if (videoClip.alphaM3u8Url) {
      const alphaKey = videoClip.alphaM3u8Url.substring(
        videoClip.alphaM3u8Url.lastIndexOf('/') + 1,
      )
      const alphaFilePath = (await checkAndInstallAssetFromIndexDB({
        key: alphaKey,
        checkVersion: false,
      })) as string
      videoClip.alphaM3u8Path = alphaFilePath
    }
    if (videoClip.reverseAlphaM3u8Url) {
      const alphaKey = videoClip.reverseAlphaM3u8Url.substring(
        videoClip.reverseAlphaM3u8Url.lastIndexOf('/') + 1,
      )
      const alphaFilePath = (await checkAndInstallAssetFromIndexDB({
        key: alphaKey,
        checkVersion: false,
      })) as string
      videoClip.reverseAlphaM3u8Path = alphaFilePath
    }
    await checkAndInstallAssetFromIndexDB({ key, checkVersion: false })
  } catch (e) {
    console.warn('The id downloaded from the remote end is：' + e)
    if (!assetUrl) {
      console.error('The address is empty, the installation failed', key)
      return
    }
    try {
      if (videoClip.alphaM3u8Url) {
        const alphaKey = videoClip.alphaM3u8Url.substring(
          videoClip.alphaM3u8Url.lastIndexOf('/') + 1,
        )
        const alphaAssetRet = await axios.get(videoClip.alphaM3u8Url, {
          responseType: 'arraybuffer',
        })
        await saveAssetToIndexDB(alphaKey, new Uint8Array(alphaAssetRet.data))
        const alphaFilePath = (await checkAndInstallAssetFromIndexDB({
          key: alphaKey,
        })) as string
        videoClip.alphaM3u8Path = alphaFilePath
      }
      if (videoClip.reverseAlphaM3u8Url) {
        const alphaKey = videoClip.reverseAlphaM3u8Url.substring(
          videoClip.reverseAlphaM3u8Url.lastIndexOf('/') + 1,
        )
        const alphaAssetRet = await axios.get(videoClip.reverseAlphaM3u8Url, {
          responseType: 'arraybuffer',
        })
        await saveAssetToIndexDB(alphaKey, new Uint8Array(alphaAssetRet.data))
        const alphaFilePath = (await checkAndInstallAssetFromIndexDB({
          key: alphaKey,
        })) as string
        videoClip.reverseAlphaM3u8Path = alphaFilePath
      }
      const assetRet = await axios.get(assetUrl, {
        responseType: 'arraybuffer',
      })
      await saveAssetToIndexDB(key, new Uint8Array(assetRet.data))
      await checkAndInstallAssetFromIndexDB({ key })
    } catch (error) {
      console.error(error)
    }
  }
}

export async function insertFontToList(keyVal: string, assets: string[]) {
  return new Promise(async function (resolve, reject) {
    for (let i = 0; i < assets.length; i++) {
      if (keyVal === assets[i]) {
        resolve(undefined)
        return
      }
    }
    let fontFamily = await loadAndInstallFont(keyVal)
    assets.push(keyVal)
    resolve(fontFamily)
  })
}

export async function loadAndInstallFont(key: string) {
  const { fonts } = store.getState().material
  const dispatch = store.dispatch
  if (key === 'Noto Color Emoji') return
  let asset = fonts.find((f: any) => {
    if (f.stringValue === key) {
      return true
    }
    // 兼容旧的xml
    // HYQiHei-55S [HYQiHei-HES]
    const temp = f.stringValue.split(' [')
    if (Array.isArray(temp) && temp[0] === key) {
      return true
    }
    return false
  })
  if (!asset) {
    console.warn('missing font', key)
    return
  }
  const familyNameFromStringValue = asset.stringValue
  const familyName = await saveAndInstallPackage(asset, '')
  if (familyNameFromStringValue.split(' [')[0] !== familyName) {
    dispatch(
      materialActions.updateFontNameObj({
        key: familyNameFromStringValue,
        value: familyName,
      }),
    )
  }
  dispatch(materialActions.updateLoadedFont(asset.stringValue))
  return asset.stringValue
}

export function installOtherFile(params: any = {}) {
  let { url, packageName, assetType = 'resources' } = params
  if (!packageName) {
    packageName = url.substring(url.lastIndexOf('/') + 1, url.length)
  }
  return new Promise((resolve, reject) => {
    checkAndInstallAssetFromIndexDB({
      key: packageName,
      checkVersion: true,
      assetType,
    })
      .then(() => {
        resolve(undefined)
      })
      .catch(() => {
        axios
          .get(url, { responseType: 'arraybuffer' })
          .then(res => {
            saveAssetToIndexDB(
              packageName,
              new Uint8Array(res.data),
              assetType,
            ).then(() => {
              checkAndInstallAssetFromIndexDB({
                key: packageName,
                checkVersion: true,
                assetType,
              })
                .then(() => {
                  resolve(undefined)
                })
                .catch(reject)
            })
          })
          .catch(reject)
      })
  })
}

// The font passed to the setFontFamily interface, with square brackets
export async function getFontFamily(font: string): Promise<string> {
  const { fonts, fontNameObj, defaultFontfamily } = store.getState().material
  const dispatch = store.dispatch
  const f = fonts.find((f: any) => {
    if (f.stringValue === font) {
      return true
    }
    // HYQiHei-55S [HYQiHei-HES]
    const temp = f.stringValue.split(' [')
    if (
      Array.isArray(temp) &&
      (temp[0] === font || font.indexOf(temp[0]) >= 0)
    ) {
      return true
    }
    return false
  })
  if (f) {
    let familyNameFromStringValue = f.stringValue
    if (!f.hasInstalled) {
      console.warn(
        'The font library exists and needs to be installed',
        familyNameFromStringValue,
      )
      const familyName = await saveAndInstallPackage(f, '')
      if (familyNameFromStringValue.split(' [')[0] !== familyName) {
        dispatch(
          materialActions.updateFontNameObj({
            key: familyNameFromStringValue,
            value: familyName,
          }),
        )
      }
      dispatch(materialActions.updateLoadedFont(familyNameFromStringValue))
      if (fontNameObj.hasOwnProperty(familyNameFromStringValue)) {
        familyNameFromStringValue = fontNameObj[familyNameFromStringValue]
      }
      return familyNameFromStringValue
    } else {
      if (fontNameObj.hasOwnProperty(familyNameFromStringValue)) {
        familyNameFromStringValue = fontNameObj[familyNameFromStringValue]
      }
      return familyNameFromStringValue
    }
  } else {
    let defaultFont = defaultFontfamily
    if (!defaultFont) {
      defaultFont = await dispatch(
        materialActions.saveAndInstallDefaultFonts(),
      ).unwrap()
    }
    console.warn(`${font} not in font library, use default font${defaultFont}`)
    return defaultFont
  }
}

export async function getFontFilePathByFontFamily(fontFamily: string) {
  const { fonts, fontNameObj } = store.getState().material
  let packageUrl = ''
  // Here, if the font name does not correspond, find the name returned by the backend
  Object.keys(fontNameObj).forEach(key => {
    if (fontNameObj[key] === fontFamily) {
      fontFamily = key
    }
  })
  for (let font of fonts) {
    if (fontFamily === font.stringValue) {
      packageUrl = font.packageUrl
      // You must jump out after finding it, otherwise if there are duplicate stringvalues, it will not be downloaded
      break
    }
  }
  if (!packageUrl) {
    throw new Error(`not found ${fontFamily} corresponding packageUrl`)
  }
  return '/font/' + packageUrl.substring(packageUrl.lastIndexOf('/') + 1)
}

function doSetAssetData(
  db: IDBDatabase,
  fileName: string,
  value: any,
  objectStoreName: string,
  resolve: Function,
) {
  if (db.objectStoreNames.contains(objectStoreName)) {
    const transaction = db.transaction(objectStoreName, 'readwrite')
    const dbStore = transaction.objectStore(objectStoreName)
    const uuid = fileName.split('.')[0]
    dbStore.put({ id: uuid, name: fileName, data: value })
    resolve()
  }
}

export function setAudioWaveData(fileName: string, value: any) {
  return new Promise((resolve, reject) => {
    if (!db) {
      let request = window.indexedDB.open(name, version)
      request.onerror = function (e) {
        reject(fileName)
        console.error('open index db error!')
      }
      request.onsuccess = function (e) {
        // @ts-expect-error
        db = e.target.result
        console.log('index db version:' + db.version)
        doSetAssetData(db, fileName, value, 'audiowave', resolve)
      }
    } else {
      doSetAssetData(db, fileName, value, 'audiowave', resolve)
    }
  })
}

function doGetAssetData(
  db: IDBDatabase,
  fileName: string,
  objectStoreName: string,
  resolve: Function,
  reject: Function,
) {
  if (db.objectStoreNames.contains(objectStoreName)) {
    let transaction = db.transaction(objectStoreName, 'readwrite')
    let dbStore = transaction.objectStore(objectStoreName)
    let uuid = fileName.split('.')[0]
    if (!uuid) {
      uuid = getUUIDFromStr(fileName)
    }
    let request = dbStore.get(uuid)
    request.onsuccess = function (e) {
      // @ts-expect-error
      let ret = e.target.result
      if (typeof ret === 'undefined' || typeof ret.data === 'undefined') {
        reject(fileName)
      } else {
        resolve(ret.data)
      }
    }
  }
}

export function getAudioWaveData(fileName: string) {
  return new Promise((resolve, reject) => {
    if (db == undefined) {
      let request = window.indexedDB.open(name, version)
      request.onerror = function (e) {
        reject(fileName)
        console.error('open index db error!')
      }
      request.onsuccess = function (e) {
        // @ts-expect-error
        db = e.target.result
        console.log('index db version:' + db.version)
        doGetAssetData(db, fileName, 'audiowave', resolve, reject)
      }
    } else {
      doGetAssetData(db, fileName, 'audiowave', resolve, reject)
    }
  })
}

export function deleteIndexDBByIndexAndName(
  index: number,
  objectStoreName: string,
) {
  const transaction = db.transaction(objectStoreName, 'readwrite')
  const dbStore = transaction.objectStore(objectStoreName)
  dbStore.delete(index)
}

export function getAssetFromIndexDB(
  id: string,
  type: string,
): Promise<{ data: ArrayBuffer | null; name: string }> | null {
  const storeNames = TEMPLATE_PACKAGES
  if (!storeNames.includes(type)) return null
  return new Promise((resolve, reject) => {
    let transaction = db.transaction(type, 'readwrite')
    let dbStore = transaction.objectStore(type)
    let request = dbStore.get(id)
    request.onsuccess = e => {
      // @ts-expect-error
      const ret = e.target.result
      if (ret) {
        resolve({ data: new Uint8Array(ret.data), name: ret.name })
      } else {
        resolve({ data: null, name: '' })
      }
    }
    request.onerror = () => {
      resolve({ data: null, name: '' })
    }
  })
}
