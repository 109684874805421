import { lazy, LazyExoticComponent } from 'react'
import { createBrowserRouter } from 'react-router-dom'

const Login = lazy(() => import('@/pages/login'))
const Home = lazy(() => import('@/pages/home/Home'))
const MyPieces = lazy(() => import('@/pages/home/MyPieces'))
const ProjectList = lazy(() => import('@/pages/home/ProjectList'))
const TemplateList = lazy(() => import('@/pages/home/TemplateList'))
const EditorApp = lazy(() => import('@/pages/editor'))

export interface IRoute {
  path: string
  redirect?: string
  meta?: {
    title?: string
    auth?: boolean
  }
  component?: LazyExoticComponent<any>
  children?: IRoute[]
}

const routes: IRoute[] = [
  {
    path: '/',
    redirect: '/home/projectList',
  },
  {
    path: '/home',
    redirect: '/home/projectList',
  },
  {
    path: '/login',
    meta: {
      title: '登录',
      auth: false,
    },
    component: Login,
  },
  {
    path: '/app/:projectId',
    meta: {
      title: '工程',
      auth: true,
    },
    component: EditorApp,
  },
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: 'myPieces',
        meta: {
          title: 'my pieces',
          auth: true,
        },
        component: MyPieces,
      },
      {
        path: 'projectList',
        meta: {
          title: 'poject list',
          auth: true,
        },
        component: ProjectList,
      },
      {
        path: 'templateList',
        meta: {
          title: 'template list',
          auth: true,
        },
        component: TemplateList,
      },
    ],
  },
]

const router = createBrowserRouter(routes, {
  basename: import.meta.env.BASE_URL,
})

export default router
