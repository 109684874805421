export function getAuthUrl() {
  // Authentication address in the configuration file
  return window.$nvsConfig.AuthUrl
}

// Whether to authenticate
export function isAuth() {
  if (window.$nvsConfig) {
    return window.$nvsConfig.isAuth
  }
  return true
}

export function getCOSHost(config = {}) {
  let cosHost = (window.$nvsConfig && window.$nvsConfig.cosHost) || ''
  return cosHost || 'https://alieasset.meishesdk.com'
}

export function getApiHost(type?: string) {
  let host = (window.$nvsConfig && window.$nvsConfig.apiHost) || ''
  if (type === 'material') {
    return host[type]
  }
  let result = ''
  if (typeof host === 'string') {
    result = host
  } else {
    if (type) {
      result = host[type]
    } else {
      result = host['project']
    }
  }
  return result
}

export function getDigitalmanHost() {
  let host = (window.$nvsConfig && window.$nvsConfig.liveApiHost) || ''
  return host
}

export function getLiveApiHost() {
  let host = (window.$nvsConfig && window.$nvsConfig.liveApiHost) || ''
  return host
}

export function getLiveWsHost() {
  let host = (window.$nvsConfig && window.$nvsConfig.liveWsHost) || ''
  return host
}
