import {
  AllClip,
  AllTrack,
  TimelineData,
  Transition,
  VideoTrack,
} from '@/utils/ProjectData'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface IProjectState {
  projectData: IProjectData
  timelineData: TimelineData
  timelineDataArray: TimelineData[]
  ratio: string
  timelineRulerRule: number
  nvLiveWindow: NvsLiveWindow
  nvTimeline: NvsTimeline
  workFlow: any
}

const initialState: IProjectState = {
  projectData: {},
  timelineData: null as unknown as TimelineData,
  timelineDataArray: [] as unknown as TimelineData[],
  ratio: '16:9',
  timelineRulerRule: 8,
  nvLiveWindow: null as unknown as NvsLiveWindow,
  nvTimeline: null as unknown as NvsTimeline,
  workFlow: null,
}

export interface IProjectData {
  [key: string]: any
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateNvLiveWindow(
      state,
      { payload }: PayloadAction<NvsLiveWindow | null>,
    ) {
      state.nvLiveWindow = payload as NvsLiveWindow
    },
    updateNvTimeline(state, { payload }: PayloadAction<NvsTimeline | null>) {
      state.nvTimeline = payload as NvsTimeline
    },
    updateTimelineRulerRule(state, { payload }: PayloadAction<number>) {
      state.timelineRulerRule = payload
    },
    updateTimelineDataTrackSelected(state, { payload }: PayloadAction<string>) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        track.clips = track.clips.map(clip => {
          return {
            ...clip,
            selected: false,
          }
        }) as any
        return {
          ...track,
          selected: track.uuid === payload,
        }
      })
    },
    clearTimelineDataClipAllInitialStatus(state) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        track.clips = track.clips.map(clip => ({
          ...clip,
          initialStatus: false,
        })) as any
        return { ...track }
      })
    },
    updateTimelineDataClipAllUnselected(state) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        track.clips = track.clips.map(clip => ({
          ...clip,
          selected: false,
        })) as any
        return { ...track }
      })
    },
    updateTimelineDataClipAllSelected(state) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        track.clips = track.clips.map(clip => ({
          ...clip,
          selected: true,
        })) as any
        return { ...track }
      })
    },
    updateTimelineDataClipSelected(
      state,
      { payload }: PayloadAction<string | string[] | undefined>,
    ) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        let isTrackSelected = false
        track.clips = track.clips.map(clip => {
          let isSelected = Array.isArray(payload)
            ? payload.includes(clip.uuid)
            : clip.uuid === payload
          if (isSelected) {
            isTrackSelected = true
          }
          return {
            ...clip,
            selected: isSelected,
            initialStatus: false,
          }
        }) as any
        return {
          ...track,
          selected: isTrackSelected,
        }
      })
    },
    updateTimelineDataClipChoices(
      state,
      { payload }: PayloadAction<string | string[] | undefined>,
    ) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        track.clips = track.clips.map(clip => {
          let isChoices = Array.isArray(payload)
            ? payload.includes(clip.uuid)
            : clip.uuid === payload
          return {
            ...clip,
            choices: isChoices,
          }
        }) as any
        return track
      })
    },
    updateTimelineDataTracks(state, { payload }: PayloadAction<AllTrack[]>) {
      state.timelineData.tracks = payload
    },
    updateTimelineDataTrack(
      state,
      {
        payload: { trackIndex, trackData },
      }: PayloadAction<{ trackIndex: number; trackData: AllTrack }>,
    ) {
      state.timelineData.tracks = state.timelineData.tracks.map(track => {
        if (track.index === trackIndex) {
          return trackData
        } else {
          return track
        }
      })
    },
    updateTimelineDataTrackVolume(
      state,
      {
        payload: { trackIndex, trackVolume },
      }: PayloadAction<{ trackIndex: number; trackVolume: number }>,
    ) {
      const trackData = state.timelineData.tracks.find(
        track => track.index === trackIndex,
      )
      if (trackData) {
        trackData.volume = trackVolume
      }
    },
    updateTimelineDataClip(
      state,
      { payload }: PayloadAction<{ trackIndex: number; clipData: AllClip }>,
    ) {
      const { clipData, trackIndex } = payload
      if (
        trackIndex < 0 ||
        !state.timelineData.tracks ||
        !state.timelineData.tracks[trackIndex].clips
      ) {
        console.warn('The trackIndex < 0 or tracks or clips is empty!')
        return
      }
      state.timelineData.tracks[trackIndex].clips[clipData.index] = clipData
    },
    deleteTimelineDataClips(state, { payload }: PayloadAction<any>) {
      Object.keys(payload).forEach(trackIndex => {
        payload[trackIndex]
          .sort((a: number, b: number) => b - a)
          .forEach((clipIndex: number) => {
            state.timelineData.tracks[Number(trackIndex)].clips.splice(
              clipIndex,
              1,
            )
            if (
              state.timelineData.tracks[Number(trackIndex)].type ===
              'videoTrack'
            ) {
              let index = (
                state.timelineData.tracks[Number(trackIndex)] as VideoTrack
              ).transitions?.findIndex(
                (value: Transition<any>) => value.index === clipIndex,
              )
              if (index !== -1) {
                ;(
                  state.timelineData.tracks[Number(trackIndex)] as VideoTrack
                ).transitions.splice(index, 1)
              }
            }
          })
      })
    },
    deleteTimelineDataTrack(state, { payload }: PayloadAction<number>) {
      state.timelineData.tracks.splice(payload, 1)
    },
    updateTimelineDataInArray(state, { payload }: PayloadAction<TimelineData>) {
      const index = state.timelineDataArray.findIndex(
        tl => tl.videoSize === payload.videoSize,
      )
      if (index > -1) {
        state.timelineDataArray[index] = payload
      } else {
        state.timelineDataArray.push(payload)
      }
    },
    updateTimelineDataArray(state, { payload }: PayloadAction<TimelineData[]>) {
      state.timelineDataArray = payload
    },
    updateTimelineTransitionSelected(
      state,
      {
        payload: { trackIndex, transitionIndex },
      }: PayloadAction<{ trackIndex: number; transitionIndex: number }>,
    ) {
      state.timelineData.tracks = (
        state.timelineData.tracks as VideoTrack[]
      ).map(track => {
        if (trackIndex === -1 && track.type === 'videoTrack') {
          track.transitions = track.transitions.map(transition => ({
            ...transition,
            selected: false,
          }))
          return track
        }
        if (track.index === trackIndex) {
          track.transitions = track.transitions.map(transition => ({
            ...transition,
            selected: transition.index === transitionIndex,
          }))
          return { ...track }
        } else {
          return track
        }
      })
    },
    updateTimelineTransitionData(
      state,
      {
        payload: { trackIndex, transition },
      }: PayloadAction<{ trackIndex: number; transition: Transition<any> }>,
    ) {
      const oldTransitions = (
        state.timelineData.tracks[trackIndex] as VideoTrack
      ).transitions
      ;(state.timelineData.tracks[trackIndex] as VideoTrack).transitions =
        oldTransitions.map(t => {
          if (t.clipUUID === transition.clipUUID) {
            return transition
          } else {
            return t
          }
        })
    },
    updateTimelineTrackAllTransitionData(
      state,
      {
        payload: { trackIndex, transitions },
      }: PayloadAction<{ trackIndex: number; transitions: Transition<any>[] }>,
    ) {
      ;(state.timelineData.tracks[trackIndex] as VideoTrack).transitions =
        transitions
    },
    deleteTimelineTransitionData(
      state,
      {
        payload: { trackIndex, transitionIndex },
      }: PayloadAction<{ trackIndex: number; transitionIndex: number }>,
    ) {
      ;(state.timelineData.tracks[trackIndex] as VideoTrack).transitions.splice(
        transitionIndex,
        1,
      )
    },
    updateRatio(state, { payload }: PayloadAction<string>) {
      state.ratio = payload
    },
    updateProjectData(state, { payload }: PayloadAction<IProjectData>) {
      if (!payload.resolvingPower) {
        payload.resolvingPower = 720
      }
      state.projectData = payload
    },
    updateTimelineData(state, { payload }: PayloadAction<any>) {
      state.timelineData = { ...state.timelineData, ...payload }
    },
    updateTimelineDataSectionData(
      state,
      {
        payload: { isShowSection, sectionInPoint, sectionOutPoint },
      }: PayloadAction<{
        isShowSection?: boolean
        sectionInPoint?: number
        sectionOutPoint?: number
      }>,
    ) {
      state.timelineData = {
        ...state.timelineData,
        isShowSection:
          isShowSection !== undefined
            ? isShowSection
            : state.timelineData.isShowSection,
        sectionInPoint:
          sectionInPoint !== undefined
            ? sectionInPoint
            : state.timelineData.sectionInPoint,
        sectionOutPoint:
          sectionOutPoint !== undefined
            ? sectionOutPoint
            : state.timelineData.sectionOutPoint,
      }
    },
    updateWorkFlow(state, { payload }: PayloadAction<any>) {
      state.workFlow = payload
    },
  },
})

export const projectActions = { ...projectSlice.actions }

export default projectSlice.reducer
