import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type TabKey = 'project' | 'my'

interface IResourceState {
  thumbnailMap: { [key: string]: any }
  waveMap: { [key: string]: any }
  currentTabKey: TabKey
  rootFolderName: string
  isDragUpload: boolean
}

const initialState: IResourceState = {
  thumbnailMap: {},
  waveMap: {},
  currentTabKey: 'project',
  rootFolderName: '',
  isDragUpload: false,
}

export const resourceSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateThumbnailMap(state, { payload }: PayloadAction<object>) {
      state.thumbnailMap = payload
    },
    updateWaveMap(state, { payload }: PayloadAction<object>) {
      state.waveMap = payload
    },
    updateCurrentTabKey(state, { payload }: PayloadAction<TabKey>) {
      state.currentTabKey = payload
    },
    updateRootFolderName(state, { payload }: PayloadAction<string>) {
      state.rootFolderName = payload
    },
    updateIsDragUpload(state, { payload }: PayloadAction<boolean>) {
      state.isDragUpload = payload
    },
  },
})

export const resourceActions = { ...resourceSlice.actions }

export default resourceSlice.reducer
