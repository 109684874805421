import { getApiHost, getDigitalmanHost, getLiveApiHost } from './network'

export const meisheApi = {
  // NOTE: Upload and packaging tasks related
  webApi_sts_info2: getApiHost('sts') + 'upload/sts/info',
  webApi_job_info: getApiHost('job') + 'job/batch/info',
  webApi_job_list: getApiHost('job') + 'job/list',
  webApi_job_delete: getApiHost('job') + 'job/delete',
  webApi_job_transcode: getApiHost('job') + 'job/transcode/createBatchJobs',
  webApi_job_cancel: getApiHost('job') + 'job/cancel',

  // NOTE: user related
  webApi_user_list: getApiHost('user') + 'user/list',
  webApi_userInfo: getApiHost('user') + 'usercenter/user/info',
  webApi_login: getApiHost('user') + 'usercenter/login',
  webApi_register: getApiHost('user') + 'usercenter/register',
  webApi_checkPhone: getApiHost('user') + 'usercenter/verify_code/check',
  webApi_checkEmail: getApiHost('user') + 'usercenter/user/check/email',
  webApi_checkPassword: getApiHost('user') + 'usercenter/user/update',
  webApi_forwardToEmail:
    getApiHost('user') + 'usercenter/verifyCode/forward/phone/to/email',
  webApi_judgeUserExist: getApiHost('user') + 'usercenter/user/exist',
  webApi_verify_code_create:
    getApiHost('user') + 'usercenter/verify_code/create',
  webApi_verify_code_image_create:
    getApiHost('user') + 'usercenter/verify_code_image/create',
  webApi_list_service_user: getApiHost('user') + 'user/listServiceUser',

  // NOTE: resource related
  webApi_resource_create: getApiHost('resource') + 'resource/create',
  webApi_resource_update: getApiHost('resource') + 'resource/update',
  webApi_resource_list: getApiHost('resource') + 'resource/list',
  webApi_resource_info: getApiHost('resource') + 'resource/info',
  webApi_resource_tag_info: getApiHost('resource') + 'resource/tag/info',
  webApi_resource_delete: getApiHost('resource') + 'resource/delete',
  webApi_resource_batch_delete: getApiHost('resource') + 'resource/batchDelete',
  webApi_resource_append: getApiHost('resource') + 'resource/append',
  webApi_resource_reverse: getApiHost('resource') + 'resource/reverse',

  // NOTE: video synchronization
  webApi_audio_align: getApiHost('audio') + 'resource/audio/compare',
  webApi_audio_result: getApiHost('audio') + 'resource/audio/cmpresult',

  // NOTE: Film related
  webApi_video_list: getApiHost('video') + 'video/list',
  webApi_video_delete: getApiHost('video') + 'video/delete',
  webApi_video_create: getApiHost('video') + 'video/create',
  webApi_video_share: getApiHost('video') + 'video/share',
  webApi_video_share_info: getApiHost('video') + 'video/share/info',

  // NOTE: material related
  webApi_material_list: getApiHost('material') + 'material/list',
  webApi_material_update: getApiHost('material') + 'material/update',
  webApi_material_all: getApiHost('material') + 'materialcenter/material/all',
  webApi_material_categories: getApiHost('material') + 'material/categories',
  webApi_material_add: getApiHost('material') + 'material/add',
  webApi_material_catalog: getApiHost() + 'project/categories',
  webApi_material_private_update:
    getApiHost('material') + 'material/private/update',
  webApi_material_private_list:
    getApiHost('material') + 'material/private/list',
  webApi_material_private_overview_list:
    getApiHost('material') + 'material/private/overviewList',
  webApi_material_private_recent_list:
    getApiHost('material') + 'material/private/recent/list',
  webApi_material_private_recent_mark:
    getApiHost('material') + 'material/private/recent/mark',
  webApi_material_private_favourite_list:
    getApiHost('material') + 'material/private/favourite/list',
  webApi_material_private_favourite_mark:
    getApiHost('material') + 'material/private/favourite/mark',
  webApi_material_listPrivate:
    getApiHost('material') + 'materialcenter/material/listPrivate',
  webApi_material_listAuthed:
    getApiHost('material') + 'materialcenter/material/listAuthed',
  webApi_material_listAll:
    getApiHost('material') + 'materialcenter/material/listAll',
  webApi_material_listTypeAndCategory:
    getApiHost('material') + 'material/listTypeAndCategory',
  webApi_material_download:
    getApiHost('material') + 'materialcenter/material/download',
  webApi_material_arrearage:
    getApiHost('material') + 'materialcenter/material/arrearageMaterial',
  webApi_material_submitMaterial:
    getApiHost('material') + 'materialcenter/material/submitMaterial',

  // NOTE: project related
  webApi_project_list: getApiHost('project') + 'project/list',
  webApi_project_info: getApiHost('project') + 'project/info',
  webApi_project_create: getApiHost('project') + 'project/create',
  webApi_project_create_empty: getApiHost('project') + 'project/create/empty',
  webApi_project_update: getApiHost('project') + 'project/update',
  webApi_project_delete: getApiHost('project') + 'project/delete',
  webApi_project_compile: getApiHost('project') + 'project/compile',
  webApi_project_history_list: getApiHost('project') + 'project/history/list',
  webApi_project_history_save: getApiHost('project') + 'project/history/save',
  webApi_project_history_update:
    getApiHost('project') + 'project/history/update',
  webApi_project_clone: getApiHost('project') + 'project/clone',
  webApi_project_version_list: getApiHost('project') + 'version/list',
  webApi_project_splitdemo_list:
    getApiHost('project') + 'splitVideo/demo/public/list',

  // Three-terminal intercommunication
  three_terminal_project_create:
    getApiHost('project') + 'threeTerminalProject/create',
  three_terminal_project_update:
    getApiHost('project') + 'threeTerminalProject/update',

  // NOTE: Live related
  webApi_project_reset: getLiveApiHost() + 'm3u8Transform/resetMeiShe',
  webApi_live_project_compile: getLiveApiHost() + 'project/compile',
  webApi_live_compile: getLiveApiHost() + 'project/compile',
  webApi_live_job_info: getLiveApiHost() + 'job/info',
  webApi_live_job_delete: getLiveApiHost() + 'cancelCompilingJob',
  webApi_live_getLiveResourceByOriginUrl:
    getLiveApiHost() + 'm3u8Transform/getLiveResourceByOriginUrl',
  webApi_live_refreshAuthKey:
    getLiveApiHost() + 'm3u8Transform/refreshM3u8UrlAuthKey',
  webApi_live_stopLiveResource:
    getLiveApiHost() + 'm3u8Transform/stopLiveResource',
  webApi_live_audio_to_text_start:
    getLiveApiHost() + 'm3u8Transform/startConnecting',
  webApi_live_audio_to_text_end:
    getLiveApiHost() + 'm3u8Transform/endConnecting',

  // Language to subtitle iFLYTEK voice transcription
  webApi_recognition_create: getApiHost('recognition') + 'recognition/create',
  webApi_recognition_info: getApiHost('recognition') + 'recognition/info',

  // smart label
  tag_all: getApiHost('tag') + 'tag/all',
  tag_fuzzySearch: getApiHost('tag') + 'tag/fuzzySearch',
  tag_create: getApiHost('tag') + 'tag/create',

  // digital human
  digitalman_list: getDigitalmanHost() + 'metaman/getDigitalAssets',
  digitalman_create: getDigitalmanHost() + 'metaman/createDigitalHumanVideos',
  digitalman_delete:
    getDigitalmanHost() + 'metaman/deleteDigitalAssetByResourceTitle',
  digitalman_job: getDigitalmanHost() + 'metaman/getDigitalHumanVideosJob',
  digitalman_info: getDigitalmanHost() + 'metaman/getDigitalAssetsDetail',
  digitalman_styles: getDigitalmanHost() + 'metaman/getStyles',
  digitalman_upload_picture:
    getDigitalmanHost() + 'metaman/uploadStylizedPictureModelings',
  digitalman_picture_job_list:
    getDigitalmanHost() + 'metaman/getStylizedPictureModelings',
  digitalman_picture_job_info:
    getDigitalmanHost() + 'metaman/getStylizedPictureModelingsDetail',
  digitalman_create_snapshot_job:
    getDigitalmanHost() + 'metaman/createModelSnapshotJob',
  digitalman_snapshot_job_info:
    getDigitalmanHost() + 'metaman/getModelSnapshotJob',
}
export default {
  // NOTE: Upload and packaging tasks related
  webApi_sts_info: getApiHost('sts') + 'sts/info',
  webApi_sts_info2: getApiHost('sts') + 'upload/sts/info',
  webApi_sts_upload_local: getApiHost('sts') + 'upload/files',
  webApi_job_info: getApiHost('job') + 'job/batch/info',
  webApi_job_list: getApiHost('job') + 'job/list',
  webApi_job_list_by_user: getApiHost('job') + 'job/listByUser',
  webApi_job_delete: getApiHost('job') + 'job/delete',
  webApi_job_retry: getApiHost('job') + 'job/retry',
  webApi_job_transcode: getApiHost('job') + 'job/transcode/createBatchJobs',
  webApi_job_cancel: getApiHost('job') + 'job/cancel',

  // NOTE: user related
  webApi_user_list: getApiHost('user') + 'user/list',
  webApi_userInfo: getApiHost('user') + 'user/info',
  webApi_login: getApiHost('user') + 'login',
  webApi_register: getApiHost('user') + 'usercenter/register',
  webApi_checkPhone: getApiHost('user') + 'usercenter/verify_code/check',
  webApi_checkEmail: getApiHost('user') + 'usercenter/user/check/email',
  webApi_checkPassword: getApiHost('user') + 'usercenter/user/update',
  webApi_forwardToEmail:
    getApiHost('user') + 'usercenter/verifyCode/forward/phone/to/email',
  webApi_judgeUserExist: getApiHost('user') + 'usercenter/user/exist',
  webApi_verify_code_create:
    getApiHost('user') + 'usercenter/verify_code/create',
  webApi_verify_code_image_create:
    getApiHost('user') + 'usercenter/verify_code_image/create',

  // NOTE: resource related
  webApi_resource_create: getApiHost('resource') + 'resource/create',
  webApi_resource_update: getApiHost('resource') + 'resource/update',
  webApi_resource_list: getApiHost('resource') + 'resource/list',
  webApi_resource_info: getApiHost('resource') + 'resource/info',
  webApi_resource_tag_info: getApiHost('resource') + 'resource/tag/info',
  webApi_resource_delete: getApiHost('resource') + 'resource/delete',
  webApi_resource_batch_delete: getApiHost('resource') + 'resource/batchDelete',
  webApi_resource_append: getApiHost('resource') + 'resource/append',
  webApi_resource_add: getApiHost('resource') + 'resource/add',
  webApi_resource_updtitle: getApiHost('resource') + 'resource/updtitle',
  webApi_resource_reverse: getApiHost('resource') + 'resource/reverse',
  webApi_resource_move: getApiHost('resource') + 'resource/moveToFolder',

  // NOTE: video synchronization
  webApi_audio_align: getApiHost('audio') + 'resource/audio/compare',
  webApi_audio_result: getApiHost('audio') + 'resource/audio/cmpresult',

  // NOTE: Film related
  webApi_video_list: getApiHost('video') + 'video/list',
  webApi_video_delete: getApiHost('video') + 'video/delete',
  webApi_video_create: getApiHost('video') + 'video/create',
  webApi_video_share: getApiHost('video') + 'video/share',
  webApi_video_share_info: getApiHost('video') + 'video/share/info',

  // NOTE: material related
  webApi_material_list: getApiHost('material') + 'materialNew/list',
  webApi_material_info: getApiHost('material') + 'materialNew/info',
  webApi_material_all: getApiHost('material') + 'materialNew/all',
  webApi_material_categories: getApiHost('material') + 'materialNew/categories',
  webApi_material_overviewList:
    getApiHost('material') + 'materialNew/overviewList',
  webApi_material_private_recent_list:
    getApiHost('material') + 'materialNew/recent/list',
  webApi_material_private_recent_mark:
    getApiHost('material') + 'materialNew/recent/mark',
  webApi_material_private_favourite_list:
    getApiHost('material') + 'materialNew/favourite/list',
  webApi_material_private_favourite_mark:
    getApiHost('material') + 'materialNew/favourite/mark',
  webApi_material_categories_multi:
    getApiHost('material') + 'materialNew/back/categories',
  webApi_material_add: getApiHost('material') + 'materialNew/user/add',
  webApi_material_catalog: getApiHost('resource') + 'project/categories',
  webApi_material_template_delete:
    getApiHost('material') + 'materialNew/delete',
  webApi_material_private_update: getApiHost('material') + 'material/update',
  webApi_material_private_list: getApiHost('material') + 'material/list',
  webApi_material_private_overview_list:
    getApiHost('material') + 'materialNew/overviewList',
  webApi_material_listPrivate:
    getApiHost('material') + 'materialcenter/material/listPrivate',
  webApi_material_listAuthed:
    getApiHost('material') + 'materialcenter/material/listAuthed',
  webApi_material_listAll:
    getApiHost('material') + 'materialcenter/material/listAll',
  webApi_material_listTypeAndCategory:
    getApiHost('material') + 'material/listTypeAndCategory',
  webApi_material_arrearage:
    getApiHost('material') + 'materialcenter/material/arrearageMaterial',
  webApi_material_submitMaterial:
    getApiHost('material') + 'materialcenter/material/submitMaterial',
  // @note api list project del update
  // NOTE: project related
  webApi_project_list: getApiHost('project') + 'project/list',
  webApi_project_info: getApiHost('project') + 'project/info',
  webApi_project_create: getApiHost('project') + 'project/create',
  webApi_project_create_empty: getApiHost('project') + 'project/create/empty',
  webApi_project_update: getApiHost('project') + 'project/update',
  webApi_project_delete: getApiHost('project') + 'project/delete',
  webApi_project_compile: getApiHost('project') + 'project/compile',
  webApi_project_history_list: getApiHost('project') + 'project/history/list',
  webApi_project_history_save: getApiHost('project') + 'project/history/save',
  webApi_project_history_update:
    getApiHost('project') + 'project/history/update',
  webApi_project_clone: getApiHost('project') + 'project/clone',
  webApi_project_version_list: getApiHost('project') + 'version/list',

  // Three-terminal intercommunication
  three_terminal_project_create:
    getApiHost('project') + 'threeTerminalProject/create',
  three_terminal_project_update:
    getApiHost('project') + 'threeTerminalProject/update',

  // Language to subtitle iFLYTEK voice transcription
  webApi_recognition_create: getApiHost('recognition') + 'recognition/create',
  webApi_recognition_info: getApiHost('recognition') + 'recognition/info',

  // smart label
  tag_all: getApiHost('tag') + 'tag/all',
  tag_fuzzySearch: getApiHost('tag') + 'tag/fuzzySearch',
  tag_create: getApiHost('tag') + 'tag/create',
}
