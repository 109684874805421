import { configureStore } from '@reduxjs/toolkit'
import app from './app'
import clipeditor from './clipeditor'
import editor from './editor'
import material from './material'
import project from './project'
import resource from './resource'
import task from './task'
import user from './user'

const store = configureStore({
  reducer: {
    material,
    project,
    resource,
    user,
    app,
    editor,
    clipeditor,
    task,
  },
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
