const enumConfig = {
  listSortType: {
    time: 0,
    status: 1,
  },
  listOrderType: {
    desc: 'desc',
    asc: 'asc',
  },
  materialType: {
    video: 1,
    audio: 2,
    picture: 3,
    folder: 8,
  },
  materialFrom: {
    upload: 1,
    materialCenter: 2,
  },
  uploadStatus: {
    waiting: 1,
    uploading: 2,
    uploadSuccess: 3,
    uploadFail: 4,
  },
  jobStatus: {
    init: 0,
    done: 1,
    fail: 2,
    cancel: 3,
    timeout: 4,
    doing: 5,
  },
  reverseStatus: {
    doing: 0,
    done: 1,
    not: 2,
  },
  splitCategory: {
    clip: 1,
    people: 2,
    voice: 3,
    word: 4,
  },
  projectType: {
    editor: 1,
    live: 2,
    template: 3,
    split: 4,
    textVideo: 5,
    meiying: 6,
    interact: 7,
    cover: 8,
  },
  jobType: {
    upload: 0, // transcoding task
    compile: 1, // compile task
    audioToText: 2, // recognition task
    projectTranscode: 3, // Engineering Transcoding Task
    audioCompare: 4, // audio comparison
    splitWord: 5, // Participle
    splitVideo: 6, // Strip
    tag: 9, // smart label
    compileWithTranscode: 11, // compile and transcode
    alphaTranscode: 12, // Transcoding with alpha channel
  },
  aspectRatios: {
    '16v9': 1,
    '1v1': 2,
    '9v16': 4,
    '4v3': 8,
    '3v4': 16,
    '18v9': 32,
    '9v18': 64,
    '21v9': 512,
    '9v21': 1024,
  } as { [key: string]: number },
  videoPlatform: {
    youtube: 'youtube',
    facebook: 'facebook',
    instagram: 'instagram',
    tiktok: 'tiktok',
  },
  uploadType: {
    upload: 0,
    transcode: 1,
  },
  ossType: {
    qiniu: 0,
    tencent: 1,
    amazon: 2,
    ali: 3,
    baidu: 4,
    local: 5,
    huawei: 6,
    ksyun: 7,
  },
  uploadModule: {
    prProject: 'pr_project',
    bsProject: 'bs_project',
    appProject: 'app_project',
    video: 'resource_video',
    image: 'resource_image',
    audio: 'resource_audio',
    template: 'material_template',
    font: 'material_font',
    temp: 'temp',
    multi_project: 'multi_project',
  },
  pieceBorder: {
    materialResource: 0,
    liveWindow: 1,
    timeline: 2,
  },
  coarseJobStatus: {
    waiting: 0,
    compileDoing: 1,
    compileDone: 2,
    compileFail: 3,
    transcodeDoing: 4,
    transcodeDone: 5,
    transcodeFail: 6,
  },
  materialAuthType: {
    public: 'public',
    authed: 'authed',
    private: 'private',
  },
  m0Type: {
    3: 'Texts',
    5: 'Transitions',
    4: 'Graphics',
    24: 'Effects',
    54: 'Animation',
    2: 'Color filters',
    9: 'Particle',
    10: 'Effects',
  },
  mType: {
    theme: 1,
    videofx: 2,
    captionstyle: 3,
    animatedsticker: 4,
    videotransition: 5,
    font: 6,
    music: 7,
    particle: 9,
    // faceSticker: 10,
    arscene: 14,
    compoundCaption: 15,
    photoAlbum: 16,
    mimo: 17,
    vlog: 18,
    template: 19,
    animationVideofx: 54,
    backgroundImage: 8,
    animationCaption: 11,
    effectfx: 10,
    animationSticker: 12,
    effectBlur: 123,
    effectMosaic: 124,
    effectShade: 125,
    effectAdjust: 126,
  },
  mediaVideoFxType: {
    filter: 2,
    animation: 54,
    effect: 10,
  },
  particleType: {
    filter: 50, // filter
    graffiti: 49, // graffiti
  },
  animationType: {
    inAnimation: 51,
    outAnimation: 52,
    loopAnimation: 53,
  },
  captionAnimationType: {
    inAnimation: 58,
    outAnimation: 59,
    animation: 60,
  },
  stickerAnimationType: {
    inAnimation: 61,
    outAnimation: 62,
    animation: 63,
  },
  // The categoryId corresponding to the subtitle
  captionType: {
    basic: 35,
    musicLyrics: 38,
    scroll: 41,
    free: 40,
    frame: 39,
    char: 37,
    renderer: 64,
    context: 65,
  },
  transitionType: {
    general: 67,
  },
  templateType: {
    standard: 1,
    selfAdaption: 2,
    aeConversion: 3,
  },
  materialTypeToRatioMap: {
    theme: 'verticalTheme',
    videofx: 'square',
    captionstyle: 'square',
    captionrenderer: 'square',
    captioncontext: 'square',
    captioninanimation: 'square',
    captionoutanimation: 'square',
    captionanimation: 'square',
    animatedsticker: 'square',
    animatedstickerinanimation: 'square',
    animatedstickeroutanimation: 'square',
    animatedstickeranimation: 'square',
    videotransition: 'square',
    particle: 'square',
    arscene: 'square',
    compoundCaption: 'horizontal',
    photoAlbum: 'verticalAlbum',
    mimo: 'horizontalLarge',
    vlog: 'horizontalLarge',
    template: 'horizontalLarge',
    effectfx: 'square',
  },
  templateEditTab: {
    edit: 'edit',
  },
  templateReplaceType: {
    videoImage: 'videoImage',
    image: 'image',
    video: 'video',
  },
  templateReplaceTypeWithNumber: {
    videoImage: 0,
    video: 1,
    image: 2,
  },
  mediaEditTab: {
    basic: 'basic',
    speed: 'speed',
    filter: 'filter',
    effect: 'effect',
    colorCorrection: 'colorCorrection',
    audio: 'audio',
    keying: 'keyer',
    animation: 'animation',
    mask: 'mask',
    background: 'background',
  },
  captionEditTab: {
    basic: 'basic',
    renderer: 'renderer',
    context: 'context',
    animation: 'animation',
    align: 'align',
  },
  stickerEditTab: {
    basic: 'basic',
    animation: 'animation',
  },
  TEXT_ALIGNMENT: {
    TEXT_ALIGNMENT_LEFT: 0,
    TEXT_ALIGNMENT_CENTER: 1,
    TEXT_ALIGNMENT_RIGHT: 2,
  },
  systemFlag: {
    material: 0,
    bseditor: 1,
  },
  fillMode: {
    envelope: 0,
    crop: 1,
    // stretch: 2
  },
  // special effects
  FX_DESC: {
    TRANSFORM_2D: 'Transform 2D',
    COLOR_PROPERTY: 'Color Property',
    VIGNETTE: 'Vignette', // Vignette
    SHARPEN: 'Sharpen', // sharpness
    BASICIMAGEADJUST: 'BasicImageAdjust', // image adjustment
    TINT: 'Tint', // coloring
    DEFINITION: 'Definition', // clarity
    DENOISE: 'Denoise', // noise removal
  },
  // Effect parameters
  FX_PARAM: {
    TRANS_X: 'Trans X', // offset
    TRANS_Y: 'Trans Y',
    SCALE_X: 'Scale X', // zoom
    SCALE_Y: 'Scale Y',
    OPACITY: 'Opacity', // transparency
    BRIGHTNESS: 'Brightness', // brightness
    CONTRAST: 'Contrast', // contrast
    SATURATION: 'Saturation', // saturation
    DEGREE: 'Degree', // Vignette
    AMOUNT: 'Amount', // sharpness
    EXPOSURE: 'Exposure', // exposure-1~1
    HIGHLIGHT: 'Highlight', // highlight-1~1
    SHADOW: 'Shadow', // shadow-1~1
    BLACKPOINT: 'Blackpoint', // fade-10~10
    VIBRANCE: 'Vibrance', // Vibrance-1~1
    TINT: 'Tint', // tone-1~1
    TEMPERATURE: 'Temperature', // color temperature-1~1
    INTENSITY: 'Intensity', // clarity0~1/noise removal0~1
  },
  // audio effects
  FX_AUDIO_DESC: {
    AUDIO_EQ: 'Audio EQ', // audio noise reduction
  },
  // Audio Effect Parameters
  FX_AUDIO_PARAM: {
    HERTZ_31: '1 Band Gain', //	-20.0
    HERTZ_40: '2 Band Gain', //	-20.0
    HERTZ_50: '3 Band Gain', //	-20.0
    HERTZ_63: '4 Band Gain', //	...
    HERTZ_80: '5 Band Gain', //
    HERTZ_100: '6 Band Gain', //
    HERTZ_125: '7 Band Gain', //
    HERTZ_160: '8 Band Gain', //
    HERTZ_200: '9 Band Gain', //
    HERTZ_250: '10 Band Gain', //	-14.7
    HERTZ_315: '11 Band Gain', //
    HERTZ_400: '12 Band Gain', //
    HERTZ_500: '13 Band Gain', //	-14.3
    HERTZ_630: '14 Band Gain', //
    HERTZ_800: '15 Band Gain', //
    HERTZ_1000: '16 Band Gain', // -11.8
    HERTZ_1250: '17 Band Gain', //
    HERTZ_1600: '18 Band Gain', //
    HERTZ_2000: '19 Band Gain', // -10.5
    HERTZ_2500: '20 Band Gain', //
    HERTZ_3200: '21 Band Gain', //
    HERTZ_4000: '22 Band Gain', // -17.7
    HERTZ_5000: '23 Band Gain', //
    HERTZ_6300: '24 Band Gain', //
    HERTZ_8000: '25 Band Gain', //
    HERTZ_10000: '26 Band Gain', //
    HERTZ_12500: '27 Band Gain', //
    HERTZ_16000: '28 Band Gain', //
    HERTZ_20000: '29 Band Gain', //
    HERTZ_25000: '30 Band Gain', //
  },
  videoTagTab: {
    envTag: '3',
    imageDef: '4',
    peopleTag: '2',
    objectTag: '1',
    specialDef: '5',
  },
  templateSearchType: {
    name: 0,
    tag: 1,
  },
  resourceListType: {
    project: '0',
    own: '1',
    public: '2',
    split: '3',
  },
  channelType: {
    stereo: '0',
    left: '1',
    right: '2',
  },
  attachmentType: {
    replaceId: 'MSTemplate-ReplaceId',
    footageId: 'MSTemplate-FootageId',
    footageTags: 'MSTemplate-FootageTags',
    footageName: 'MSTemplate-FootageName',
    footageM3u8Name: 'MSTemplate-FootageM3U8Name',
    footageCanReplace: 'MSTemplate-FootageCanReplace',
    footageNeedReverse: 'MSTemplate-FootageNeedReverse',
    volumeType: 'MSTemplate-VolumeType',
    footageType: 'MSTemplate-FootageType',
    fxGroup: 'MSTemplate-FxGroup',
    fxSeekTime: 'MSTemplate-BestSeekTime',
    clipRawWidth: 'MSTemplate-ClipRawWidth',
    clipRawHeight: 'MSTemplate-ClipRawHeight',
    curveSpeedType: 'MSTemplate-CurveSpeedType',
    trimIn: 'MSTemplate-TrimIn',
    trimOut: 'MSTemplate-TrimOut',
  },
  captionFormat: {
    txt: 'txt',
    srt: 'srt',
    csv: 'csv',
  },
  liveType: {
    tv: '1',
    dazhibo: '2',
  },
  // The value corresponds to FS, and the key corresponds to the key of indexeddb
  assetPackageType: {
    dat: 'dat',
    model: 'model',
    font: -2,
    m3u8: -1,
    videofx: 0,
    transition: 1,
    captionstyle: 2,
    animatedsticker: 3,
    theme: 4,
    capturescene: 5,
    ARScene: 6,
    compoundcaption: 7,
    captioncontext: 8,
    captionrenderer: 9,
    captionanimation: 10,
    captioninanimation: 11,
    captionoutanimation: 12,
    template: 13,
    animatedstickeranimation: 16,
    animatedstickerinanimation: 17,
    animatedstickeroutanimation: 18,
    project: 22,
  },
  backgroundFillType: {
    'Image File': 'image',
    Blur: 'blur',
    'Color Solid': 'color',
  },
  letterSpacingType: {
    percentage: 0,
    absolute: 1,
  },
  captionKFParamType: {
    scaleX: 'Caption ScaleX',
    scaleY: 'Caption ScaleY',
    transX: 'Caption TransX',
    transY: 'Caption TransY',
    rotation: 'Caption RotZ',
  },
  stickerKFParamType: {
    scale: 'Sticker Scale',
    transX: 'Sticker TransX',
    transY: 'Sticker TransY',
    rotation: 'Sticker RotZ',
  },
  fxKFParamType: {
    intensity: 'Filter Intensity',
  },
  multiProjectInternalSubType: {
    backgroundimage: 'backgroundimage',
    resources: 'resources',
  },
  customStorageMap: {
    smg: 'smg',
    wenge: 'wenge',
    pku: 'local',
    cmbchina: 'local',
    henantai: 'local',
    bohui: 'local',
    rongyi: 'local',
    xunfei: 'local',
    yicai: 'aliyun',
    quanshi: 'aliyun',
    qilu: 'qiniu',
    yingpu: 'huawei',
  },
  historySaveType: {
    manual: 0,
    automatic: 1,
  },
  projectSaveStatus: {
    init: 0,
    connecting: 1,
    saving: 2,
    saved: 3,
    failed: 4,
  },
  uploadFrom: {
    computer: 0,
    phone: 1,
    googleDrive: 2,
    googlePhotos: 3,
    dropbox: 4,
    iCloud: 5,
  },
}

if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
  enumConfig.mType = {
    theme: 1,
    videofx: 2,
    captionstyle: 3,
    animatedsticker: 4,
    videotransition: 5,
    font: 6,
    music: 7,
    particle: 9,
    // faceSticker: 10,
    arscene: 14,
    compoundCaption: 15,
    photoAlbum: 16,
    mimo: 17,
    vlog: 18,
    template: 19,
    animationVideofx: 21,
    backgroundImage: 22,
    animationCaption: 23,
    effectfx: 24,
    animationSticker: 25,
    effectBlur: 123,
    effectMosaic: 124,
    effectShade: 125,
    effectAdjust: 126,
  }
  enumConfig.captionType = {
    basic: 1,
    musicLyrics: 2,
    scroll: 3,
    free: 4,
    frame: 5,
    char: 6,
    // stretch: 7
    // compound: 8
    renderer: 9,
    context: 10,
  }
  enumConfig.animationType = {
    inAnimation: 1,
    outAnimation: 2,
    loopAnimation: 3,
  }
  enumConfig.captionAnimationType = {
    inAnimation: 3,
    outAnimation: 4,
    animation: 5,
  }
  enumConfig.stickerAnimationType = {
    inAnimation: 1,
    outAnimation: 2,
    animation: 3,
  }
  enumConfig.mediaVideoFxType = {
    filter: 2,
    animation: 21,
    effect: 24,
  }
}

export default enumConfig
