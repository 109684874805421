import store from '@/store'
import { userActions } from '@/store/user'
import { getToken } from '@/utils/auth'
import EventBus from '@/utils/EventBus'
import {
  getProjectSaveResponseCode,
  setProjectSaveResponseCode,
  setProjectSaveResponseCodeChanged,
} from '@/utils/projectSave'
import axios, { AxiosRequestConfig } from 'axios'

// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config: any) => {
    // do something before request is sent
    const {
      user: { token },
    } = store.getState()
    if (token) {
      if (import.meta.env.VITE_CONFIG_NAME === 'meishe') {
        config.headers.token = getToken()
      } else {
        config.headers.Authorization = getToken()
      }
    }

    if (config.version) {
      config.headers.version = config.version
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    let { code, msg = '', enMsg = '' } = response.data

    let saveApi = response.config.url?.indexOf('project/history/save')
    if (saveApi) {
      let prevCode = getProjectSaveResponseCode()
      setProjectSaveResponseCodeChanged(code !== parseInt(prevCode))
      setProjectSaveResponseCode(code)
    }

    const {
      config: { url },
    } = response
    msg = enMsg || msg
    const dispatch = store.dispatch
    // if the custom code is not 0, it is judged as an error.
    if (code !== 0) {
      if (code === -2) {
        EventBus.$emit('customMessages', {
          message: 'Please login first',
          type: 'warning',
        })
        console.warn('Please login first')
        dispatch(userActions.resetState())
        location.href = '/login'
        return
      }
      EventBus.$emit('customMessages', {
        message: `${url} ${msg}`,
        type: 'warning',
      })
      console.warn(msg)
      return Promise.reject(msg)
    } else {
      return response.data
    }
  },
  error => {
    console.error('Network Error')
    return Promise.reject(error)
  },
)

export const get = ({
  url,
  headers,
}: {
  url: string
  headers: AxiosRequestConfig
}) => {
  return service.get(url, headers).then(res => {
    return res.data
  })
}

export const post = ({
  url,
  data,
  headers,
  onUploadProgress,
}: {
  url: string
  data: any
  headers?: any
  onUploadProgress?: any
}) => {
  return service.post(url, data, { headers, onUploadProgress }).then(res => {
    return res.data
  })
}

export const delet = ({
  url,
  headers,
}: {
  url: string
  headers: AxiosRequestConfig
}) => {
  return service.delete(url, headers).then(res => {
    return res.data
  })
}
