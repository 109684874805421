import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  duration: number
  rulerRule: number
} = {
  duration: 0,
  rulerRule: 1,
}

export const clipeditorSlice = createSlice({
  name: 'clipeditor',
  initialState,
  reducers: {
    updateDuration(state, { payload }: PayloadAction<number>) {
      state.duration = payload
    },
    updateRulerRule(state, { payload }: PayloadAction<number>) {
      state.rulerRule = payload
    },
  },
})

export const clipeditorActions = { ...clipeditorSlice.actions }

export default clipeditorSlice.reducer
